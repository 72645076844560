import styled from "@emotion/styled"
import React, { useState } from "react"
import { colors, fonts, device } from "./layout/GlobalStyles"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import { Button } from "./layout/StyledComponents"

const MainContainer = styled.div`
  padding: 72px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  h3 {
    margin: 0 0 35px 0px;
    font-size: 60px;
    font-weight: 800;
    letter-spacing: -1.88px;
    line-height: 65px;
  }
  p {
    margin: 0;
    letter-spacing: -0.69px;
    line-height: 28px;
    max-width: 303px;
    font-weight: 400;
  }
  a {
    color: ${colors.secondary302};
  }
  a:hover {
    .featured-image {
      transform: scale(0.97);
    }
    p,
    span {
      color: ${colors.primary2};
    }
  }
  @media ${device.tablet} {
    flex-direction: row;
    flex-wrap: wrap;
  }
  @media ${device.laptop} {
    .title-button-container {
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
      flex-direction: row;
    }
    padding: 128px 0px 24px;
  }
`

const Panels = styled.div`
  display: flex;

  justify-content: space-between;

  flex-wrap: wrap;
  @media ${device.tablet} {
    margin-top: 72px;
    justify-content: start;
  }
`

const Panel = styled.div`
  display: flex;

  flex-direction: column;
  align-items: center;
  margin-bottom: 70px;
  span {
    align-self: start;
    font-size: 22px;
    font-weight: 900;
    letter-spacing: -0.69px;
    line-height: 28px;
    margin: 32px 0px 0px 0px;
    color: ${colors.secondary302};
    font-family: ${fonts.primary};
  }

  p {
    align-self: start;
    font-size: 22px;
    font-weight: 400;
    letter-spacing: -0.69px;
    line-height: 28px;
    margin: 0;
  }
  .featured-image {
    margin: 0 auto;
    cursor: pointer;
    transition: 0.2s ease-in-out;
  }

  @media ${device.tablet} {
    width: 302px;

    p {
      font-size: 22px;
      font-weight: 400;
      letter-spacing: -0.69px;
      line-height: 28px;
    }
    .featured-image {
      margin: 0 auto;
      img {
        width: 302px;
      }
    }
  }
  @media ${device.laptop} {
    width: 352px;
    margin-right: 18px;
    .featured-image {
      img {
        width: 352px;
      }
    }
  }
`

const FilterButton = styled.div`
  height: 72px;
  width: 100%;
  border-radius: 43px;
  background-color: #672e31;
  margin-bottom: 32px;
  .selection-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 72px;
    padding: 0px 24px;
    position: relative;
  }
  .line {
    display: none;
  }
  .main-button {
    top: 5px;
    transition: 0.2s cubic-bezier(0.33, 1, 0.68, 1);
    left: ${({ index }) =>
      (index === 0 && "5px") ||
      (index === 1 && "69px") ||
      (index === 2 && "216px")};
    height: 62.5px;
    /* width: 179px; */
    width: fit-content;
    color: ${colors.secondary303};
    position: absolute;
    border-radius: 43px;
    z-index: 3;
    :hover {
      border: 2px solid ${colors.secondary202};
      color: #341719;
      background: ${colors.secondary202};
    }
    :active {
      color: ${colors.secondary303};
      background: ${colors.secondary202};
    }
    :focus {
      color: ${colors.secondary303};
      background: ${colors.secondary202};
    }
  }
  .button-0:after {
    color: ${colors.secondary303};
    content: "All";
    font-size: 18px;
    font-weight: 900;
    letter-spacing: -0.56px;
    line-height: 27px;
  }
  .button-1:after {
    color: ${colors.secondary303};
    content: "Foundation";
    font-size: 18px;

    font-weight: 900;
    letter-spacing: -0.56px;
    line-height: 27px;
  }

  .button-2:after {
    color: ${colors.secondary303};
    content: "Church";
    font-size: 18px;
    font-weight: 900;
    letter-spacing: -0.56px;
    line-height: 27px;
  }
  .button-3:after {
    color: ${colors.secondary303};
    font-size: 18px;
    font-weight: 900;
    letter-spacing: -0.56px;
    line-height: 27px;
    content: "Executive board - Kenya";
  }
  .button-4:after {
    font-size: 18px;
    width: 209px;
    display: inline-block;
    font-weight: 900;
    letter-spacing: -0.56px;
    line-height: 27px;
    color: ${colors.secondary303};
    content: "Executive Board - USA";
  }

  button + button {
    background: none;
    border: none;
    color: ${colors.white};
    font-family: ${fonts.primary};
    font-size: 18px;
    font-weight: 900;
    letter-spacing: -0.56px;
    line-height: 27px;
    text-align: center;
  }
  @media ${device.tablet} {
    padding: 0px 36px 0px;
    width: 568px;
    .line {
      position: absolute;
      display: block;
      width: 1px;
      height: 31px;
      background: white;
    }
    .line-one {
      left: 135px;
    }
    .line-two {
      left: 415px;
    }
    .selection-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .all-button {
    }
    .foundation-button {
    }
    .main-button {
      top: 5px;
      transition: 0.2s cubic-bezier(0.33, 1, 0.68, 1);
      left: ${({ index }) =>
        (index === 0 && "-30px") ||
        (index === 1 && "179px") ||
        (index === 2 && "400px")};
      height: 62.5px;
      /* width: 179px; */
      width: 199px;
    }
  }
`

export const PeoplePanelsCopy = ({ allPeople }) => {
  const [index, setIndex] = useState(0)
  const [people, setPeople] = useState(allPeople)

  const optionSelect = (i, categoryOption) => {
    setIndex(i)
    if (categoryOption === "all") {
      setPeople(allPeople)
    } else if (categoryOption === "church" || categoryOption === "foundation") {
      let arr = []
      allPeople.map(person => {
        person.node.frontmatter.category.filter(
          category => category === categoryOption && arr.push(person)
        )
      })
      setPeople(arr)
    }
  }
  return (
    <MainContainer>
      <div className="container">
        <div className="title-button-container">
          <h3>Our people</h3>

          <FilterButton index={index}>
            <div className="selection-container">
              <div className="line line-one"></div>
              <div className="line line-two"></div>
              <Button
                className={`button-${index} main-button`}
                color="yellow"
              ></Button>
              <button
                className="all-button"
                onClick={() => {
                  optionSelect(0, "all")
                }}
              >
                All
              </button>
              <button
                className="foundation-button"
                onClick={() => {
                  optionSelect(1, "foundation")
                }}
              >
                Foundation
              </button>
              <button
                onClick={() => {
                  optionSelect(2, "church")
                }}
              >
                Church
              </button>
            </div>
          </FilterButton>
        </div>
        <Panels>
          {people.map(
            ({
              node: {
                frontmatter: { slug, name, image, title },
              },
            }) => {
              return (
                <Link to={slug}>
                  <Panel>
                    <GatsbyImage
                      className="featured-image"
                      image={getImage(image)}
                      alt="frontal shot"
                    />
                    <span>{name}</span>
                    <p>{title}</p>
                  </Panel>
                </Link>
              )
            }
          )}
        </Panels>
      </div>
    </MainContainer>
  )
}
