import React from "react"
import { PeopleHero } from "../components/People-Hero"
import { Furthering } from "../components/Furthering"
import { PeoplePanelsCopy } from "../components/People-Panels-Copy"
import { graphql } from "gatsby"
import { ElderExecutive } from "../components/Elder-Executive"
import { Locations } from "../components/Locations"
import { ContactUs } from "../components/Contact-Us"
import { Seo } from "../components/Seo"

const PeopleCopy = ({
  data: {
    allPeople: { edges },
  },
}) => {
  const allPeople = edges
  return (
    <>
      <Seo
        title="We honor the complexity of every person’s story"
        description="MLIFE Church & Foundation - We are religious and secular. BIPOC and White. LGBTQ+ and not."
      />{" "}
      <>
        <PeopleHero />
        <Furthering />
        <PeoplePanelsCopy allPeople={allPeople} />
        <ElderExecutive />
        <Locations />
        <ContactUs />
      </>
    </>
  )
}

export const query = graphql`
  query MyQueryCopy {
    allPeople: allMdx(sort: { fields: frontmatter___order, order: ASC }) {
      edges {
        node {
          id
          frontmatter {
            slug
            name
            order
            category
            title
            image {
              childImageSharp {
                gatsbyImageData(formats: AUTO, placeholder: NONE, quality: 100)
              }
            }
          }
        }
      }
    }
  }
`

export default PeopleCopy
